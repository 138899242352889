// extracted by mini-css-extract-plugin
export var appSlider = "latest_blogs-module--app-slider--tTS7G";
export var blog = "latest_blogs-module--blog--3-Ca2";
export var blogContainer = "latest_blogs-module--blog-container--2mjDK";
export var blogs = "latest_blogs-module--blogs--15a_n";
export var contentWrapper = "latest_blogs-module--content-wrapper--1cPev";
export var defaultContainer = "latest_blogs-module--default-container--2b923";
export var featureSlider = "latest_blogs-module--feature-slider--1TnVv";
export var innerPagesSlider = "latest_blogs-module--inner-pages-slider--2DIXS";
export var leftAppSlide = "latest_blogs-module--left-app-slide--1RDQM";
export var logoSlider = "latest_blogs-module--logo-slider--2K5g1";
export var phoneAppSelect = "latest_blogs-module--phone-app-select--3S0VY";
export var phoneCaseSelect = "latest_blogs-module--phone-case-select--V7Ul_";
export var rightAppSlide = "latest_blogs-module--right-app-slide--9E5yf";
export var slickActive = "latest_blogs-module--slick-active--3pK1N";
export var slickArrow = "latest_blogs-module--slick-arrow--233IQ";
export var slickCenter = "latest_blogs-module--slick-center---0PHZ";
export var slickDots = "latest_blogs-module--slick-dots--SlFHR";
export var slickNext = "latest_blogs-module--slick-next--dPhl2";
export var slickPrev = "latest_blogs-module--slick-prev--rgZWF";
export var slickSlide = "latest_blogs-module--slick-slide--1tLxE";
export var slickSlider = "latest_blogs-module--slick-slider--1CJzY";
export var slickTrack = "latest_blogs-module--slick-track--3Ij3A";
export var solvingSlider = "latest_blogs-module--solving-slider--GpUgc";
export var subtitle = "latest_blogs-module--subtitle--32hMm";
export var title = "latest_blogs-module--title--1Fjq7";
export var titleWrapper = "latest_blogs-module--title-wrapper--2vrRq";
import React from "react"
import { useEffect, useState } from "react"

enum Phase {
  Typing,
  Deleting,
  Pausing,
}

const TYPING_INTERVAL = 100
const DELETING_INTERVAL = 100
const PAUSING_INTERVAL = 1000

export const useTyped = (
  pretext,
  strings,
  onScreen?: boolean,
  deleteAll?: boolean
): { base: string; text: string } => {
  const [index, setIndex] = useState(0)
  const [phase, setPhase] = useState(Phase.Typing)
  const [text, setText] = useState("")
  const [base, setBase] = useState("")

  if (strings[0] !== undefined && pretext[0] !== undefined) {
    useEffect(() => {
      if (onScreen === false) {
        return
      }
      switch (phase) {
        case Phase.Typing: {
          const nextText = strings[index].slice(0, text.length + 1)
          const nextPre = pretext[index].slice(0, base.length + 1)
          if (nextText === text) {
            setPhase(Phase.Pausing)
            return
          }
          const timeout = setTimeout(() => {
            if (pretext[index] === base) {
              setText(nextText)
            } else {
              setBase(nextPre)
            }
          }, TYPING_INTERVAL)
          return () => clearTimeout(timeout)
        }

        case Phase.Deleting: {
          if (deleteAll) {
            if (text) {
              const nextRemaining = strings[index].slice(0, text.length - 1)
              const timeout = setTimeout(() => {
                setText(nextRemaining)
              }, DELETING_INTERVAL)
              return () => clearTimeout(timeout)
            } else if (base) {
              const nextBaseRemaining = pretext[index].slice(0, base.length - 1)
              const timeout = setTimeout(() => {
                setBase(nextBaseRemaining)
              }, DELETING_INTERVAL)
              return () => clearTimeout(timeout)
            } else {
              setPhase(Phase.Typing)
              const nextIndex = index + 1
              setIndex(strings[nextIndex] ? nextIndex : 0)
              return
            }
          } else {
            if (!text) {
              setPhase(Phase.Typing)
              const nextIndex = index + 1
              setIndex(strings[nextIndex] ? nextIndex : 0)
              return
            }
            const nextRemaining = strings[index].slice(0, text.length - 1)
            const timeout = setTimeout(() => {
              setText(nextRemaining)
            }, DELETING_INTERVAL)
            return () => clearTimeout(timeout)
          }
        }

        case Phase.Pausing: {
          const timeout = setTimeout(
            () => setPhase(Phase.Deleting),
            PAUSING_INTERVAL
          )
          return () => clearTimeout(timeout)
        }

        default:
          return
      }
    }, [strings, text, index, phase, base, deleteAll])
  }
  return { base, text }
}

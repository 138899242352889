// extracted by mini-css-extract-plugin
export var appSlider = "hints-module--app-slider--1Jm7K";
export var blinkCaret = "hints-module--blink-caret--1jfzN";
export var caret = "hints-module--caret--eatAB";
export var caretMove = "hints-module--caret-move--3r3x4";
export var cover = "hints-module--cover--8VGkr";
export var defaultContainer = "hints-module--default-container--1_K7b";
export var featureSlider = "hints-module--feature-slider--3A8NX";
export var innerPagesSlider = "hints-module--inner-pages-slider--146Ut";
export var leftAppSlide = "hints-module--left-app-slide--34qJO";
export var logoSlider = "hints-module--logo-slider--3X5y_";
export var phoneAppSelect = "hints-module--phone-app-select--1iyIH";
export var phoneCaseSelect = "hints-module--phone-case-select--1nKZ_";
export var pink = "hints-module--pink--1Iu8i";
export var rightAppSlide = "hints-module--right-app-slide--WiFIf";
export var slickActive = "hints-module--slick-active--W0TB2";
export var slickArrow = "hints-module--slick-arrow--1LUQs";
export var slickCenter = "hints-module--slick-center--V6OHE";
export var slickDots = "hints-module--slick-dots--3QA8D";
export var slickNext = "hints-module--slick-next--2gJEa";
export var slickPrev = "hints-module--slick-prev--2BdH5";
export var slickSlide = "hints-module--slick-slide--19pWg";
export var slickSlider = "hints-module--slick-slider--TWhs7";
export var slickTrack = "hints-module--slick-track--PBp9P";
export var solvingSlider = "hints-module--solving-slider--3iJwD";
export var subtitle = "hints-module--subtitle--3vzck";
export var subtitleNoAnimation = "hints-module--subtitle-no-animation--2dPkI";
export var subtitles = "hints-module--subtitles--11EQm";
export var typing = "hints-module--typing--cLL7A";
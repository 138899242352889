import React from "react"
import BlogCard from "../../components/blog_card/blog_card"
import * as styles from "./latest_blogs.module.scss"
import { animationSlideRight } from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"

export default function LatestBlogs(props) {
  const { content, blogs } = props

  return (
    <section className={styles.blog}>
      <div className="default-container">
        <div className={styles.blogContainer}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title} {...animationSlideRight()}>
              Latest Blogs
            </h2>
            <Hints
              hints={[{ parts: [""] }]}
              isAnimation={true}
              typingBackground={
                content?.backgroundColor
                  ? content.backgroundColor.hex
                  : "#422E88"
              }
            />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.blogs}>
              {blogs?.nodes.map((feature, index) => (
                <BlogCard key={index} blogDetails={feature} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import React from "react"
import { graphql } from "gatsby"
import { PortableText } from "@portabletext/react"
import myPortableTextComponents from "../../utils/blog-serializer"
import { displayDate } from "../../utils/functions"
import Layout from "../../components/Layout"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import LatestBlogs from "../../sections/latest_blogs/latest_blogs"
import * as styles from "./blog_inner.module.scss"
import { siteMetadata } from "../../utils/seo"

export default function BlogInner({ data }) {
  const { title, author, createDate, tags, category, body, image } =
    data.currentBlog
  return (
    <Layout>
      {/* <Seo title={"Home"} /> */}
      <section className={styles.blogInner}>
        {/* <div className={styles.headerBanner}
          style={{backgroundImage: `url(${image.image.asset.url})`}}
        > */}
        <div className={styles.headerBanner}>
          <img src={image.image.asset.url} alt="blog-main-image"></img>
        </div>
        <div className="default-container">
          <div className={styles.blogInnerContainer}>
            <div className={styles.content}>
              <div className={styles.titleWrapper}>
                <h1 className={styles.title}>{title}</h1>
                <div className={styles.info}>
                  {author?.name && (
                    <span className={styles.author}>Author: {author.name}</span>
                  )}
                  <span className={styles.category}>
                    {category.length > 0 &&
                      category.map((tag, index) => (
                        <React.Fragment key={index}>
                          <React.Fragment> {tag.name}</React.Fragment>
                          {index != category.length - 1 && (
                            <React.Fragment key={index + `-comma`}>
                              ,
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ))}
                  </span>
                  <span className={styles.date}>
                    {createDate && displayDate(createDate)}
                  </span>
                </div>
              </div>
              <hr />
              <div className={styles.contentWrapper}>
                <PortableText
                  value={body}
                  components={myPortableTextComponents(styles)}
                />
              </div>
              <hr />
              <div className={styles.tagsWrapper}>
                {tags.length > 0 && (
                  <p>
                    Tags:
                    <span>
                      {tags.map((tag, index) => (
                        <React.Fragment key={index}>
                          <React.Fragment> {tag.name}</React.Fragment>
                          {index != tags.length - 1 && (
                            <React.Fragment key={index + `-comma-tag`}>
                              ,
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ))}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <LatestBlogs blogs={data.relatedBlog} />
    </Layout>
  )
}

export const Head = ({ data }) => {
  const { seoKeywords, seoDescription, seoTitle, image } = data.currentBlog
  const title = seoTitle ? seoTitle : siteMetadata.title
  const description = seoDescription ? seoDescription : siteMetadata.description
  const keywords = seoKeywords ? seoKeywords : siteMetadata.keywords
  const imageSeo = image.image.asset.url
    ? data.currentBlog.image.image.asset.url
    : siteMetadata.image
  return (
    <>
      <title>{title}</title>
      <meta name="language" content={siteMetadata.lang} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={siteMetadata.author} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageSeo} />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:site_name" content={siteMetadata.siteUrl} />
      <meta property="og:locale" content={siteMetadata.lang} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteMetadata.siteUrl} />
      <meta name="twitter:creator" content={siteMetadata.author} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageSeo} />
    </>
  )
}

export const query = graphql`
  query BlogInnerPage($slug: String) {
    currentBlog: sanityBlog(slug: { current: { eq: $slug } }) {
      title
      author {
        name
      }
      category {
        name
      }
      tags {
        name
      }
      seoTitle
      seoDescription
      seoKeywords
      image {
        image {
          asset {
            url
          }
        }
      }
      createDate
      body: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    relatedBlog: allSanityBlog(
      limit: 3
      sort: { fields: createDate, order: DESC }
      filter: { slug: { current: { ne: $slug } } }
    ) {
      nodes {
        createDate(formatString: "YYYY/MM/DD")
        image {
          image {
            asset {
              url
            }
          }
        }
        title
        summary
        slug {
          current
        }
      }
    }
  }
`

import React from "react"

export default function myPortableTextComponents(styles) {
  const CustomBlogList = ({ value }) => {
    return (
      value.customList.length > 0 && (
        <div className={styles.customList}>
          <div className={styles.customListBar}> </div>
          <div className={styles.customListContent}>
            {value.customList.map((list, index) => (
              <div key={index} className={styles.listItem}>
                <p className={styles.listItemTitle}>{list.listTitle}</p>
                <p className={styles.listItemSubtitle}>{list.listSubTitle}</p>
                <p className={styles.listItemConent}>{list.listContent}</p>
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  const CustomBlogOrderedList = ({ value }) => {
    return (
      value.customList.length > 0 && (
        <div className={styles.customList}>
          <div className={styles.customListBar}> </div>
          <div className={styles.customListContent}>
            {value.customList.map((list, index) => (
              <div key={index} className={styles.listItem}>
                <p className={styles.listItemConent}>
                  {index + 1}. {list}
                </p>
              </div>
            ))}
          </div>
        </div>
      )
    )
  }

  const CustomBlogContentImage = ({ value }) => {
    return (
      <div className={styles.customImage}>
        <div className={styles.customImageTitle}>
          <h1 className={styles.h1Title}>{value.title}</h1>
        </div>
        <div className={styles.customImageWrapper}>
          <img src={value.image.asset.url} alt={value.alt} />
        </div>
        <div className={styles.customImageCaption}>
          <p>
            <figcaption>{value.caption}</figcaption>
          </p>
        </div>
      </div>
    )
  }

  const CustomVideoIframe = ({ value }) => {
    return (
      value?.video_link && (
        <div className={styles.customVideo}>
          <div className={styles.customVideoTitle}>
            <h1 className={styles.h1Title}>{value.title}</h1>
          </div>
          <div
            className={styles.customVideoIframe}
            style={value.type == "audio" ? { maxHeight: "150px" } : {}}
          >
            <iframe src={value.video_link}></iframe>
          </div>
          {value.caption && (
            <div className={styles.customVideoCaption}>
              <p>
                <figcaption>{value.caption}</figcaption>
              </p>
            </div>
          )}
        </div>
      )
    )
  }

  const CustomTable = ({ value }) => {
    console.log(value)
    return (
      value?.rows.length > 0 && (
        <table className={styles.customTable}>
          <tbody className={styles.customTableBody}>
            {value?.rows.map((row, idx) => (
              <tr key={idx} className={idx == 0 ? styles.tableHeader : ""}>
                {row.cells.map((cell, idxc) => (
                  <td key={idxc}>
                    <p>{cell}</p>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )
    )
  }

  return {
    types: {
      image: ({ value }) => <img src={value.imageUrl} />,
      table: CustomTable,
      blog_custom_list: CustomBlogList,
      blog_custom_ordered_list: CustomBlogOrderedList,
      blog_embed_video: CustomVideoIframe,
      blog_image: CustomBlogContentImage,
      callToAction: ({ value, isInline }) =>
        isInline ? (
          <a href={value.url}>{value.text}</a>
        ) : (
          <div className="callToAction">{value.text}</div>
        ),
    },
    marks: {
      link: ({ children, value }) => {
        const rel = !value.href.startsWith("/")
          ? "noreferrer noopener"
          : undefined
        return (
          <a href={value.href} rel={rel}>
            {children}
          </a>
        )
      },
    },
    list: {
      bullet: ({ children }) => (
        <div style={{ paddingLeft: "3.5em" }}>
          <ul>{children}</ul>
        </div>
      ),
      number: ({ children }) => (
        <div style={{ paddingLeft: "3.5em" }}>
          <ol>{children}</ol>
        </div>
      ),
      // checkmarks: ({children}) => <ol className="m-auto text-lg">{children}</ol>,
    },
    block: {
      // Ex. 1: customizing common block types
      h1: ({ children }) => <h1 className={styles.h1Title}>{children}</h1>,
      h2: ({ children }) => <h2 className={styles.h2Title}>{children}</h2>,
      h3: ({ children }) => <h3 className={styles.h3Title}>{children}</h3>,
      blockquote: ({ children }) => <blockquote>{children}</blockquote>,
    },
  }
}

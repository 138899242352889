import React from "react"
import * as styles from "./hints.module.scss"
import { useTyped } from "../../utils/useTyped"
import { useAnimateOnScreen } from "../../utils/useAnimateOnScreen"

export default function Hints(props) {
  const { hints, isAnimation, hintsFontSize, deleteAllText } = props
  const getHints = () => {
    let pre = []
    let hintsArray = []

    if (!hints) return { pre, hintsArray }
    hints.forEach(element => {
      if (element.parts[1]) {
        hintsArray.push(element.parts[1])
        //console.log(element.parts[0])
        pre.push(element.parts[0])
      } else {
        hintsArray.push(element.parts[0])
        // pre = ""
      }
    })
    return { pre, hintsArray }
  }

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  }
  const { pre, hintsArray } = getHints()
  const { ref, isIntersecting } = useAnimateOnScreen(options)

  const { base, text } = useTyped(
    pre,
    hintsArray,
    isIntersecting,
    deleteAllText
  )

  return (
    <React.Fragment>
      <>
        {hints?.length > 0 && (
          <div ref={ref} className={styles.subtitles}>
            {isAnimation ? (
              <p
                className={styles.subtitle}
                style={{ fontSize: hintsFontSize }}
              >
                {base}
                <span className={pre.length > 2 ? styles.pink : ""}>
                  {text}
                </span>
                {hintsArray.length > 0 && pre.length > 0 && (
                  <span className={styles.caret}></span>
                )}
              </p>
            ) : (
              <p
                className={`${styles.subtitle} ${styles.subtitleNoAnimation}`}
                style={{ fontSize: hintsFontSize }}
              >
                {hintsArray.map((part, index) => (
                  <span key={index}>{part}</span>
                ))}
              </p>
            )}
          </div>
        )}
      </>
    </React.Fragment>
  )
}

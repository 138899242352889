import React, { useEffect, useRef, useState } from "react"

//animate element on scroll

interface options {
  root: any
  rootMargin: string
  threshold: number
}

export const useAnimateOnScreen = (options: options) => {
  const ref = useRef(null)
  const [isIntersecting, setIntersecting] = useState(false)

  const callbackFunction = (entries: any) => {
    const [entry] = entries
    setIntersecting(entry.isIntersecting)
  }
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (ref.current) observer.observe(ref.current)
    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [ref, options])
  return { ref, isIntersecting }
}
